import React from 'react';
import './_style.scss';
import MyComponent from '~/components/test';

const TestPage: React.FC = () => {
    return (
        <div className='test-container p16'>
            <h1>Hello World!</h1>
            <p>This is a test page</p>
            <MyComponent />
        </div>
    );
};

export default TestPage;