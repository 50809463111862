import React from 'react';
import { useParams } from 'react-router';
import { Flex, Form, FormProps } from 'antd';
import { BookingFieldType } from '~/pages/BookingPage/EventFormPage';
import EventDetailForm from './EventDetailForm';
import EventTimeForm from './EventTimeForm';
import GoBackButton from './GoBackBtn';
import EventInfo from './EventInfo';
import Card from '~/components/Card';
import { useExternalBookingContext } from '../../context/ExternalBookingContext';
import { getLocationByID } from '../../modules/selectors';
import { ErrorCode, ExternalBookingRequest } from '../../constants/types';
import { formatAddress, formatPhoneNumber, getErrorMsg } from '../../modules';
import Button from '~/components/Button';
import { strings } from '~/feature/externalBooking/constants/strings';
import Text from '~/components/typography/Text';
import useWindowMediaQuery from '../../hooks/useWindowMediaQuery';
import FooterLogo from '~/components/FooterLogo';
import ReCAPTCHA from 'react-google-recaptcha';

const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
    <>
        {label}
        <span>{required ? '*' : null}</span>
    </>
);

const styles: Record<string, React.CSSProperties> = {
    container: {
        marginTop: 32,
    },
    card: {
        maxWidth: 900,
    },
    btnWrapper: {
        width: '100%',
    },
    btn: {
        minWidth: 200,
        marginTop: 30,
    },
};

const EventBookingForm: React.FC = () => {
    const [step, setStep] = React.useState(1);
    const {
        trainerInfo,
        eventType,
        locations,
        appointmentTime,
        locationId,
        error,
        completeBooking,
        setError,
    } = useExternalBookingContext();
    const { trainerId, eventTypeId } = useParams();
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const reCaptchaRef = React.createRef<ReCAPTCHA>();
    const [isRecaptchaLoaded, setIsRecaptchaLoaded] = React.useState(false);
    const [form] = Form.useForm();
    const RECAPTCHA_SITE_KEY = window.TZ_ME.Globals.recaptcha_site_key;

    const handleGoBack = () => {
        setStep(1);
    };

    const getLocationName = () => {
        if (step === 1) return undefined;
        const selectedLocation = getLocationByID(locations, locationId);
        return selectedLocation && formatAddress(selectedLocation);
    };

    const handleSubmit: FormProps<BookingFieldType>['onFinish'] = () => {
        if (step === 1) {
            setStep(2);
        } else {
            reCaptchaRef.current?.execute();
        }
    };

    const asyncScriptOnLoad = () => {
        setIsRecaptchaLoaded(true);
    };

    const handleRecaptcha = (token: string | null) => {
        const values = form.getFieldsValue();
        const { clientPhone, ...restValues } = values;
        if (token) {
            if (eventType && trainerId && eventTypeId && appointmentTime) {
                const data: ExternalBookingRequest = {
                    ...restValues,
                    trainerID: parseInt(trainerId),
                    startTime: appointmentTime,
                    appointmentTypeID: parseInt(eventTypeId),
                    locationID: eventType.isVirtual ? undefined : locationId,
                    clientPhone: formatPhoneNumber(clientPhone),
                    clientVerifyToken: token,
                };
                completeBooking(data);
            } else {
                setError(getErrorMsg());
            }
        } else {
            handleRecaptchaError();
        }
    };

    const handleRecaptchaError = () => {
        setError(getErrorMsg(ErrorCode.recaptcha));
    };

    return (
        <>
            <Card style={styles.card} hideBorderOnSmallDevice>
                {step === 2 && <GoBackButton handleGoBack={handleGoBack} />}
                {eventType && trainerInfo && (
                    <EventInfo
                        appointmentType={eventType}
                        trainerName={`${trainerInfo.firstName} ${trainerInfo.lastName}`}
                        {...(step === 2 ? { startTime: appointmentTime, locationName: getLocationName() } : {})}
                    />
                )}
                <Flex gap="large" vertical style={styles.container}>
                    <Form
                        name="externalBooking"
                        layout="vertical"
                        onFinish={handleSubmit}
                        requiredMark={customizeRequiredMark}
                        form={form}
                    >
                        {step === 1 ? <EventTimeForm /> : <EventDetailForm />}
                        {appointmentTime && (
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{
                                        ...styles.btn,
                                        ...(applyMinMediaQuery ? styles.btnWrapper : {}),
                                    }}
                                    disabled={!isRecaptchaLoaded}
                                >
                                    {step === 1 ? strings.continueCTA : strings.form.submit}
                                </Button>
                            </Form.Item>
                        )}
                        {error && <Text size="small" useSpan type="danger" text={error} />}
                    </Form>
                </Flex>
                <FooterLogo />
            </Card>
            <ReCAPTCHA
                size="invisible"
                ref={reCaptchaRef}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleRecaptcha}
                asyncScriptOnLoad={asyncScriptOnLoad}
                onErrored={handleRecaptchaError}
                onExpired={handleRecaptchaError}
            />
        </>
    );
};

export default EventBookingForm;
