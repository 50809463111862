import React, { useEffect, useRef, useState } from 'react';
import { Button as AntButton, ConfigProvider, GlobalToken, theme } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import Icon from '~/components/Icon';
import { ICON_COLORS, ICONS } from '~/constant/icons.mjs';
import { FC, Props } from './constants/types';
import { addOpacityToHex } from '~/modules/utils';

const getIconSize = (size: SizeType, token: GlobalToken) => {
    switch (size) {
        case 'small':
            return token.Button?.contentFontSizeSM;
        case 'middle':
            return token.Button?.contentFontSize;
        case 'large':
            return token.Button?.contentFontSizeLG;
    }
};

const { useToken } = theme;
const Button: FC<Props> = ({
    icon = '',
    iconOnly = false,
    iconColor = undefined,
    iconSize = undefined,
    btnColor = undefined,
    className,
    throttleDuration = 1000,
    onClick = () => void 0,
    children,
    size = 'middle',
    ...restProps
}) => {
    const { token } = useToken();

    const [isThrottling, setIsThrottling] = useState<boolean>(false);
    const throttlingId = useRef<number | undefined>(undefined);
    useEffect(() => {
        return () => {
            if (throttlingId.current) {
                clearTimeout(throttlingId.current);
            }
        };
    }, []);

    const throttlingStart = () => {
        const THROTTLE_DURATION = throttleDuration; // throttle duration in ms
        setIsThrottling(true);
        throttlingId.current = window.setTimeout(() => setIsThrottling(false), THROTTLE_DURATION);
    };

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!isThrottling) {
            onClick(e);
            throttlingStart();
        }
    };

    const renderContent = () => {
        return (
            <span>
                {icon && (
                    <Icon
                        icon={icon}
                        color={iconColor}
                        disabled={false}
                        size={iconSize ?? getIconSize(size, token)}
                        style={{ marginRight: iconOnly ? 0 : 4 }}
                    />
                )}
                {!iconOnly && children}
            </span>
        );
    };

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: btnColor ?? token.colorPrimary,
                    colorPrimaryHover: btnColor ? addOpacityToHex(btnColor, 0.5) : token.colorPrimaryHover,
                },
            }}
        >
            <AntButton className={className} onClick={handleClick} size={size} {...restProps}>
                {renderContent()}
            </AntButton>
        </ConfigProvider>
    );
};

Button.color = ICON_COLORS;
Button.icon = ICONS;

export default Button;
