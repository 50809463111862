import {
    ExternalBookingRequest,
    AvailableTimeSlotsResponse,
    LocationsResponse,
    TrainerInfoResponse,
    AppointmentTypeListResponse,
    AppointmentTypeResponse,
    ExternalBookingResponse,
} from '~/feature/externalBooking/constants/types';
import RequestService, { ServiceResponse } from './RequestService';

export default class ExternalBookingService {
    service: RequestService;
    constructor(service: RequestService) {
        this.service = service;
    }

    getTrainerInfo(trainerID: number): Promise<ServiceResponse<TrainerInfoResponse>> {
        return this.service.fetch<TrainerInfoResponse>({
            url: '/externalBooking/getTrainerInfo',
            body: { trainerID },
        });
    }

    getLocationList(trainerID: number): Promise<ServiceResponse<LocationsResponse>> {
        return this.service.fetch<LocationsResponse>({
            url: '/externalBooking/getLocationList',
            body: { trainerID },
        });
    }

    getAvailableTimeSlots(
        trainerID: number,
        appointmentTypeID: number,
        startTime?: string,
        endTime?: string,
        locationID?: number,
    ): Promise<ServiceResponse<AvailableTimeSlotsResponse>> {
        return this.service.fetch<AvailableTimeSlotsResponse>({
            url: '/externalBooking/getAvailableTimeslots',
            body: { trainerID, appointmentTypeID, startTime, endTime, locationID },
        });
    }

    getEventTypeList(trainerID: number): Promise<ServiceResponse<AppointmentTypeListResponse>> {
        return this.service.fetch<AppointmentTypeListResponse>({
            url: '/externalBooking/getAppointmentTypeList',
            body: { trainerID },
        });
    }

    getEventType(trainerID: number, appointmentTypeID: number): Promise<ServiceResponse<AppointmentTypeResponse>> {
        return this.service.fetch<AppointmentTypeResponse>({
            url: '/externalBooking/getAppointmentType',
            body: { trainerID, appointmentTypeID },
        });
    }

    completeBooking(data: ExternalBookingRequest): Promise<ServiceResponse<ExternalBookingResponse>> {
        return this.service.fetch({
            url: '/externalBooking/completeBooking',
            body: {
                ...data,
            },
        });
    }
}
