export const strings = {
    phoneCall: 'Phone Call',
    inPerson: 'In-Person',
    form: {
        label: {
            firstName: 'First name',
            lastName: 'Last name',
            email: 'Email',
            phone: 'Phone number',
            clientNote: 'Please share any additional information to help prepare for our meeting.',
        },
        validation: {
            firstName: 'First name required.',
            lastName: 'Last name required.',
            email: 'Email required.',
            phone: 'Phone number required.',
            validPhone: 'Please enter a valid phone number.',
        },
        submit: 'Schedule Appointment',
        agreement: [
            'By proceeding, you confirm that you have read and agree to ',
            'Trainerize Terms',
            ' of Use and',
            ' Privacy Notice.',
        ],
    },
    result: {
        title: 'Appointment Booked Successfully',
        message: 'An invitation has been sent to your email',
    },
    error: {
        default: 'Something went wrong. Please try again later.',
        accountAlreadyExist: 'You already have an account. Please use your app to book an appointment.',
        recaptcha: 'Something went wrong. Please try again after refreshing the page.',
    },
    eventListCaption: 'Hi, please select an event below to book a session with me.',
    continueCTA: 'Continue',
};

export const locationSelect = {
    title: 'Location',
    placeholder: 'Select location',
    noData: 'No location found',
};
