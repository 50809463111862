import React from 'react';
import { Card as AntCard, CardProps } from 'antd';
import useWindowMediaQuery from '~/feature/externalBooking/hooks/useWindowMediaQuery';
import { createStyles } from 'antd-style';
import classNames from 'classnames';

type Props = CardProps & {
    hideBorderOnSmallDevice?: boolean;
    hideShadow?: boolean;
};
const useStyle = createStyles(({ prefixCls, css }) => ({
    cardStyle: css`
        &.${prefixCls}-card {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
        }
    `,
    noBoxShadow: css`
        &.${prefixCls}-card:not(.${prefixCls}-card-bordered) {
            box-shadow: none;
        }
    `,
}));

const Card: React.FC<Props> = ({ hideBorderOnSmallDevice, bordered, hideShadow, ...rest }) => {
    const { styles } = useStyle();
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const applyBorder = applyMinMediaQuery && hideBorderOnSmallDevice ? false : bordered;

    const cardClass = classNames({
        [styles.cardStyle]: !hideShadow,
        [styles.noBoxShadow]: hideBorderOnSmallDevice,
    });

    return (
        <AntCard
            size={applyMinMediaQuery ? 'small' : 'default'}
            className={cardClass}
            bordered={applyBorder}
            {...rest}
        />
    );
};

export default Card;
