import React from 'react';
import { Flex } from 'antd';
import Text from '~/components/typography/Text';
import Title from '~/components/typography/Title';
import { strings } from '~/feature/externalBooking/constants/strings';
import useWindowMediaQuery from '~/feature/externalBooking/hooks/useWindowMediaQuery';
import EventInfo from '../EventInfo';
import Card from '~/components/Card';
import { useExternalBookingContext } from '~/feature/externalBooking/context/ExternalBookingContext';
import { getLocationByID } from '~/feature/externalBooking/modules/selectors';
import FooterLogo from '~/components/FooterLogo';

const styles: Record<string, React.CSSProperties> = {
    card: {
        maxWidth: 900,
    },
};

const BookingResult: React.FC = () => {
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const { eventType, trainerInfo, locations, appointmentTime, locationId } = useExternalBookingContext();

    const selectedLocation = getLocationByID(locations, locationId);

    if (!eventType || !trainerInfo) {
        return null;
    }
    return (
        <Flex gap="large" align="center" vertical>
            <Title text={strings.result.title} level={2} />
            <Text text={strings.result.message} size="large" center={!applyMinMediaQuery} />
            <Card style={styles.card}>
                {eventType && trainerInfo && (
                    <EventInfo
                        appointmentType={eventType}
                        trainerName={`${trainerInfo.firstName} ${trainerInfo.lastName}`}
                        startTime={appointmentTime}
                        locationName={selectedLocation?.name}
                    />
                )}
                <FooterLogo />
            </Card>
        </Flex>
    );
};

export default BookingResult;
