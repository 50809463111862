import React from 'react';
import { Flex } from 'antd';
import Text from '~/components/typography/Text';
import Title from '~/components/typography/Title';
import Icon from '~/components/Icon';
import { ExternalBookingType } from '../../../constants/types';
import { getFormatIcon, getFormatStr, getTimeFormatStr } from '../../../modules';
import { convertToLocalDate, getEndDateTime } from '~/modules/utils/datetime';

type Props = {
    appointmentType: ExternalBookingType;
    trainerName?: string;
    locationName?: string;
    startTime?: string;
    phoneNumber?: string;
};

const Header: React.FC<Props> = ({
    appointmentType: { name, duration, actionInfo, description },
    trainerName,
    locationName,
    startTime,
    phoneNumber,
}) => {
    const startTimeInLocal = startTime ? convertToLocalDate(startTime) : undefined;
    const endTimeInLocal = startTimeInLocal ? getEndDateTime(startTimeInLocal, duration) : undefined;

    return (
        <Flex gap={12} vertical>
            {trainerName && <Title text={trainerName} level={5} />}
            <Title text={name} level={3} style={{ paddingBottom: 4 }} />
            <Flex gap="small">
                <Icon icon={Icon.icon.filled.duration} size={Icon.size.large} />
                <Text type="secondary" text={`${duration} min`} />
            </Flex>
            <Flex gap="small">
                <Icon icon={getFormatIcon(actionInfo.isPhoneCall)} size={Icon.size.large} />
                <Text type="secondary" text={phoneNumber ? phoneNumber : getFormatStr(actionInfo.isPhoneCall)} />
            </Flex>
            {startTimeInLocal && endTimeInLocal && (
                <Flex gap="small">
                    <Icon icon={Icon.icon.filled.appointments} size={Icon.size.large} />
                    <Text type="secondary" text={getTimeFormatStr(startTimeInLocal, endTimeInLocal)} />
                </Flex>
            )}
            {locationName && (
                <Flex gap="small">
                    <Icon icon={Icon.icon.filled.location} size={Icon.size.large} />
                    <Text type="secondary" text={locationName} />
                </Flex>
            )}
            {description && <Text type="secondary" text={description ?? ''} style={{ paddingTop: 4 }} />}
        </Flex>
    );
};

export default Header;
