import React from 'react';
import './_style.scss';

const TestComponent: React.FC = () => {
    return (
        <div>
            Hello world from <span className="test-span">test component</span>
        </div>
    );
};

export default TestComponent;