import { createBrowserRouter } from 'react-router';
import TestPage from 'pages/test';
import BookingOutlet from './pages/BookingPage';
import NotFoundPage from './pages/NotFoundPage';
import EventListPage from './pages/BookingPage/EventListPage';
import EventBookingFormPage from './pages/BookingPage/EventFormPage';

const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <TestPage />,
        },
        {
            path: 'booking/',
            element: <BookingOutlet />,
            children: [
                { path: ':groupId/:trainerId', element: <EventListPage /> },
                { path: ':groupId/:trainerId/:eventTypeId', element: <EventBookingFormPage /> },
            ],
        },
        {
            path: '*',
            element: <NotFoundPage />,
        },
    ],
    { basename: '/app' },
);

export default router;
