import { useNavigate } from 'react-router';
import { Col, Flex, Row } from 'antd';
import Text from '~/components/typography/Text';
import Title from '~/components/typography/Title';
import Icon from '~/components/Icon';
import Button from '~/components/Button';
import Loader from '~/components/Loader';
import InvalidScreen from '~/components/InvalidScreen';
import Card from '~/components/Card';
import { strings } from '~/feature/externalBooking/constants/strings';
import { invalidScreen as invalidScreenStrings } from '~/components/InvalidScreen/constants/strings';
import EventInfo from '~/feature/externalBooking/ui/eventTypeForm/EventInfo';
import { useExternalBookingContext } from '~/feature/externalBooking/context/ExternalBookingContext';

const styles: Record<string, React.CSSProperties> = {
    container: {
        padding: '70px 20px 0px',
    },
    cardWrapper: {
        marginBottom: 32,
        cursor: 'pointer',
    },
    button: {
        width: 44,
        height: 44,
    },
};

const EventListPage = () => {
    const navigate = useNavigate();
    const { isLoading, error, trainerInfo, eventTypes } = useExternalBookingContext();

    if (!isLoading && (error || (eventTypes && eventTypes.length === 0))) {
        return <InvalidScreen alertText={invalidScreenStrings.invalidBookingLink} />;
    }

    return isLoading ? (
        <Loader fullScreen />
    ) : (
        <Flex gap="large" align="center" vertical style={styles.container}>
            {trainerInfo && (
                <Flex gap="large" align="center" vertical>
                    <Title text={`${trainerInfo.firstName} ${trainerInfo.lastName}`} level={2} />
                    <Text text={strings.eventListCaption} type="secondary" size="large" center />
                </Flex>
            )}
            <Row justify="center" style={{ width: '100%' }}>
                {eventTypes?.map(eventType => (
                    <Col key={eventType.appointmentTypeID} lg={16} md={18} xs={24}>
                        <Card
                            style={styles.cardWrapper}
                            size="default"
                            onClick={() => navigate(`${eventType.appointmentTypeID}`)}
                        >
                            <Flex gap="small" align="center" justify="space-between">
                                <EventInfo appointmentType={eventType} />
                                <Button
                                    icon={Icon.icon.outlined.rightArrow}
                                    iconColor={Icon.color.BLACK}
                                    iconSize={Icon.size.xlarge}
                                    iconOnly
                                    btnColor={Button.color.blueGray}
                                    type="primary"
                                    style={styles.button}
                                />
                            </Flex>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Flex>
    );
};

export default EventListPage;
