import { addMinutes, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const APIDateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
export const APIDateFormat = 'yyyy-MM-dd';

export const DisplayTimeFormat = 'h:mm a';
export const DisplayMonthYearFormat = 'MMMM yyyy';
export const DisplayDayDateMonthFormat = 'iiii, d MMMM'; // Monday, 1 January

export const convertToUtc = (inputDateTime: Date | string, dateFormat: string = APIDateTimeFormat): string => {
    const dateTime = typeof inputDateTime === 'string' ? new Date(inputDateTime) : inputDateTime;
    return formatInTimeZone(dateTime, 'UTC', dateFormat);
};

export const convertToLocalDate = (inputDateTime: Date | string, dateFormat: string = APIDateTimeFormat): string => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // if the format of the inputDateTime is '2025-02-04 10:00:00', we need to emphasize that it is in UTC
    const utcDate = typeof inputDateTime === 'string' ? new Date(inputDateTime + ' UTC') : inputDateTime;
    return formatInTimeZone(utcDate, localTimeZone, dateFormat);
};

export const getEndDateTime = (
    startDateTime: Date | string,
    duration: number,
    dateFormat: string = APIDateTimeFormat,
): string => {
    const startDate = typeof startDateTime === 'string' ? new Date(startDateTime) : startDateTime;
    const endDateTime = addMinutes(startDate, duration);
    return format(endDateTime, dateFormat);
};
