import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { add, startOfDay } from 'date-fns';
import {
    AvailableTimeSlotByUTCDate,
    ExternalBookingRequest,
    ExternalBookingType,
    Location,
    TrainerInfoResponse,
} from '../constants/types';
import RequestService from '~/modules/api/RequestLayer';
import { getErrorMsg, isOutOfRange, handlesDST } from '../modules';
import { convertToUtc } from '~/modules/utils/datetime';
import { MAX_BOOKING_WINDOW } from '../constants';
import { getAvailableDates } from '../modules/selectors';
import { RequestError } from '~/modules/api/types';

export type Props = {
    children?: ReactNode;
};

interface IExternalBookingContext {
    trainerInfo?: TrainerInfoResponse;
    eventTypes?: ExternalBookingType[];
    eventType?: ExternalBookingType;
    locations: Location[];
    locationId?: number;
    availableDates?: string[];
    timeSlots?: AvailableTimeSlotByUTCDate;
    selectedDate?: Date;
    appointmentTime?: string;
    isLoading: boolean;
    error?: string;
    isBooked: boolean;
    setLocationId: (newLocationID?: number) => void;
    setSelectedDate: (date: Date) => void;
    setAppointmentTime: (dateTime: string) => void;
    completeBooking: (value: ExternalBookingRequest) => void;
    setError: (error: string) => void;
}

const initState: IExternalBookingContext = {
    trainerInfo: undefined,
    eventTypes: undefined,
    eventType: undefined,
    locationId: undefined,
    locations: [],
    availableDates: undefined,
    timeSlots: undefined,
    appointmentTime: undefined,
    isLoading: true,
    error: undefined,
    isBooked: false,
    setLocationId: () => null,
    setSelectedDate: () => null,
    setAppointmentTime: () => null,
    completeBooking: () => null,
    setError: () => null,
};

export const ExternalBookingContext = createContext<IExternalBookingContext>(initState);

const ExternalBookingProvider: FC<Props> = ({ children }) => {
    const { trainerId, eventTypeId } = useParams();

    const [bookingContext, setBookingContext] = useState<IExternalBookingContext>({
        ...initState,
        setLocationId,
        setSelectedDate,
        setAppointmentTime,
        completeBooking,
    });

    const { eventType, locationId } = bookingContext;

    const fetchTrainerInfo = async (id: number) => {
        try {
            setIsLoading(true);
            const { data } = await RequestService.ExternalBookingService.getTrainerInfo(id);
            setBookingContext(prevContext => ({
                ...prevContext,
                trainerInfo: data,
                isLoading: false,
            }));
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(getErrorMsg(requestError?.data?.code));
        }
    };

    const fetchEventTypes = async (id: number) => {
        try {
            setIsLoading(true);
            const {
                data: { appointmentTypes },
            } = await RequestService.ExternalBookingService.getEventTypeList(id);
            setBookingContext(prevContext => ({
                ...prevContext,
                eventTypes: appointmentTypes,
                isLoading: false,
            }));
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(getErrorMsg(requestError?.data?.code));
        }
    };

    const fetchLocationList = async (id: number) => {
        try {
            setIsLoading(true);
            const { data } = await RequestService.ExternalBookingService.getLocationList(id);
            setBookingContext(prevContext => ({
                ...prevContext,
                locations: data.locations,
                isLoading: false,
            }));
            setLocationId(data.locations[0]?.locationID);
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(getErrorMsg(requestError?.data?.code));
        }
    };

    const fetchEventType = async (trainerID: number, appointmentTypeID: number) => {
        try {
            setIsLoading(true);
            const {
                data: { appointmentType },
            } = await RequestService.ExternalBookingService.getEventType(trainerID, appointmentTypeID);
            setBookingContext(prevContext => ({
                ...prevContext,
                eventType: appointmentType,
                isLoading: false,
            }));
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(getErrorMsg(requestError?.data?.code));
        }
    };

    const fetchAvailableTimeslots = async (
        trainer: number,
        apptType: number,
        startTime: string,
        endTime: string,
        location?: number,
    ) => {
        try {
            setIsLoading(true);
            const dates = handlesDST(startTime, endTime);
            const promises = dates.map(async date => {
                const { data } = await RequestService.ExternalBookingService.getAvailableTimeSlots(
                    trainer,
                    apptType,
                    date.startDateTime,
                    date.endDateTime,
                    location,
                );
                return data.dailyTimeslots;
            });
            // Wait for all the API calls to complete and gather the results
            const results = await Promise.all(promises);
            // Merge all the results into a single object
            const dailyTimeSlots = results.reduce(
                (acc, dailyTimeslots) => ({
                    ...acc,
                    ...dailyTimeslots,
                }),
                {},
            );
            setAvailableTimeSlots(dailyTimeSlots);
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(getErrorMsg(requestError?.data?.code));
        }
    };

    const completeBookingEvent = async (data: ExternalBookingRequest) => {
        try {
            setIsLoading(true);
            const response = await RequestService.ExternalBookingService.completeBooking(data);
            if (response.data.code === 0) {
                setBookingContext(prevContext => ({
                    ...prevContext,
                    isBooked: true,
                    setIsLoading: false,
                }));
            }
        } catch (error: unknown) {
            const requestError = error as RequestError;
            setError(getErrorMsg(requestError?.data?.code));
        }
    };

    useEffect(() => {
        if (trainerId && eventType && (eventType.isVirtual || locationId)) {
            const startTime = convertToUtc(startOfDay(new Date()));
            const endTime = convertToUtc(startOfDay(add(new Date(), { months: MAX_BOOKING_WINDOW })));
            fetchAvailableTimeslots(parseInt(trainerId), eventType.appointmentTypeID, startTime, endTime, locationId);
        }
    }, [eventType, locationId]);

    useEffect(() => {
        if (trainerId) {
            fetchTrainerInfo(parseInt(trainerId));
            if (eventTypeId) {
                fetchEventType(parseInt(trainerId), parseInt(eventTypeId));
            } else {
                fetchEventTypes(parseInt(trainerId));
            }
        }
    }, [trainerId, eventTypeId]);

    useEffect(() => {
        if (trainerId && eventType && !eventType?.isVirtual) {
            fetchLocationList(parseInt(trainerId));
        }
    }, [eventType]);

    function setLocationId(id?: number) {
        setBookingContext(prevContext => ({
            ...prevContext,
            locationId: id,
        }));
    }

    function setAvailableTimeSlots(dailyTimeSlots?: AvailableTimeSlotByUTCDate) {
        const availableDates = getAvailableDates(dailyTimeSlots);
        const firstDate = availableDates.length > 0 ? new Date(availableDates[0]) : undefined;
        const isValidDate = firstDate && !isOutOfRange(firstDate);
        setBookingContext(prevContext => ({
            ...prevContext,
            availableDates,
            timeSlots: dailyTimeSlots,
            selectedDate: isValidDate ? firstDate : undefined,
            isLoading: false,
        }));
    }

    function setSelectedDate(date: Date) {
        setBookingContext(prevContext => ({
            ...prevContext,
            selectedDate: startOfDay(date),
            appointmentTime: undefined,
        }));
    }

    function setAppointmentTime(dateTime: string) {
        setBookingContext(prevContext => ({
            ...prevContext,
            appointmentTime: dateTime,
        }));
    }

    function setIsLoading(value: boolean) {
        setBookingContext(prevContext => ({
            ...prevContext,
            isLoading: value,
        }));
    }

    function setError(error: string) {
        setBookingContext(prevContext => ({
            ...prevContext,
            error,
            isLoading: false,
        }));
    }

    function completeBooking(value: ExternalBookingRequest) {
        completeBookingEvent(value);
    }

    return <ExternalBookingContext.Provider value={{ ...bookingContext }}>{children}</ExternalBookingContext.Provider>;
};

export function useExternalBookingContext(): IExternalBookingContext {
    const context = useContext<IExternalBookingContext>(
        ExternalBookingContext as React.Context<IExternalBookingContext>,
    );
    if (!context) {
        throw new Error('useExternalBookingContext must be used under IExternalBookingContext');
    }
    return context;
}

export default ExternalBookingProvider;
