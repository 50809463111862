import React from 'react';
import { Flex } from 'antd';
import useWindowMediaQuery from '~/feature/externalBooking/hooks/useWindowMediaQuery';
import { ExternalBookingRequest } from '~/feature/externalBooking/constants/types';
import EventBookingForm from '~/feature/externalBooking/ui/eventTypeForm';
import BookingResult from '~/feature/externalBooking/ui/eventTypeForm/BookingResult';
import { useExternalBookingContext } from '~/feature/externalBooking/context/ExternalBookingContext';
import InvalidScreen from '~/components/InvalidScreen';
import { invalidScreen as invalidScreenStrings } from '~/components/InvalidScreen/constants/strings';
import Loader from '~/components/Loader';

const styles: Record<string, React.CSSProperties> = {
    container: {
        padding: '70px 20px',
    },
    mobileContainer: {
        padding: '35px 20px 0px 20px',
    },
};

export type BookingFieldType = {
    clientFirstName: ExternalBookingRequest['clientFirstName'];
    clientLastName: ExternalBookingRequest['clientLastName'];
    clientEmail: ExternalBookingRequest['clientEmail'];
    clientNote: ExternalBookingRequest['clientNote'];
    clientPhone: { countryCode: string; nationalPhoneNumber: string };
};

const EventBookingFormPage: React.FC = () => {
    const { applyMinMediaQuery } = useWindowMediaQuery();
    const { isLoading, error, isBooked, trainerInfo, eventType } = useExternalBookingContext();

    // if there is no data for trainer or eventType based on the id show invalid screen
    if (!trainerInfo || !eventType) {
        if (isLoading) {
            return <Loader fullScreen />;
        } else if (error) {
            return <InvalidScreen alertText={invalidScreenStrings.invalidBookingLink} />;
        }
    }

    return (
        <Flex
            gap="large"
            align="center"
            vertical
            style={{
                ...(applyMinMediaQuery ? styles.mobileContainer : styles.container),
            }}
        >
            {isBooked ? <BookingResult /> : <EventBookingForm />}
        </Flex>
    );
};

export default EventBookingFormPage;
