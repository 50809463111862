import { add, endOfDay, format, getMinutes, isAfter, isBefore, startOfDay, sub } from 'date-fns';
import { strings } from '../constants/strings';
import Icon from '~/components/Icon';
import { ErrorCode, Location } from '../constants/types';
import { MAX_BOOKING_WINDOW } from '../constants';
import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber';
import { BookingFieldType } from '~/pages/BookingPage/EventFormPage';
import { convertToLocalDate, convertToUtc } from '~/modules/utils/datetime';

export const getFormatStr = (isPhoneCall: boolean) => {
    return isPhoneCall ? strings.phoneCall : strings.inPerson;
};

export const getFormatIcon = (isPhoneCall: boolean) => {
    return isPhoneCall ? Icon.icon.filled.phone : Icon.icon.filled.inPerson;
};

export const shortenOClock = (date: string) => {
    return getMinutes(date) === 0 ? format(date, 'h a') : format(date, 'h:mm a');
};

/**
 * @returns format startTime and endTime. ex) 10 AM - 11 AM, Friday, 4 April, 2024
 */
export const getTimeFormatStr = (startTime: string, endTime: string) => {
    const formattedStartTime = shortenOClock(startTime);
    const formattedEndTime = shortenOClock(endTime);
    const formattedDate = format(startTime, 'EEEE, d MMMM, yyyy');

    const formattedString = `${formattedStartTime} - ${formattedEndTime}, ${formattedDate}`;
    return formattedString;
};

export const getErrorMsg = (errorCode?: ErrorCode) => {
    switch (errorCode) {
        // TODO: based on error code from API, return the corresponding error message
        case ErrorCode.recaptcha:
            return strings.error.recaptcha;
        default:
            return strings.error.default;
    }
};

export const formatAddress = (location: Location) => {
    const { address1, address2, city, state, country, zipCode, name } = location;
    const address = [address1, address2, city, state, country, zipCode].filter(Boolean).join(', ');
    return address === '' ? name : address;
};

export const allowMonthChange = (nextDate: Date) => {
    const today = startOfDay(new Date());
    const minDate = sub(today, { months: 1 });
    const maxDate = add(today, { months: MAX_BOOKING_WINDOW });
    return minDate.getMonth() < nextDate.getMonth() && maxDate.getMonth() > nextDate.getMonth();
};

export const isOutOfRange = (date: Date) => {
    const minDate = startOfDay(new Date());
    const maxDate = add(minDate, { months: MAX_BOOKING_WINDOW });
    return isBefore(date, minDate) || isAfter(date, maxDate);
};

/**
 * Get an instance of @type {libphonenumber.PhoneNumber} with country code and national phone number.
 * @param countryCode
 * @param nationalPhoneNumber
 * @returns @type {libphonenumber.PhoneNumber} when the phone number is valid, @type {null} when the phone number is not valid
 */
const phoneUtil = PhoneNumberUtil.getInstance();
const parsePhoneNumber = (countryCode: string, nationalPhoneNumber: string): libphonenumber.PhoneNumber | null => {
    try {
        return phoneUtil.parse(`${countryCode}${nationalPhoneNumber}`);
    } catch {
        return null;
    }
};

export const formatPhoneNumber = ({ countryCode, nationalPhoneNumber }: BookingFieldType['clientPhone']) => {
    const parsedNumber = parsePhoneNumber(countryCode, nationalPhoneNumber);
    // Expecting a successful parse in any case because the phone data should be validated before submit
    if (parsedNumber) {
        return phoneUtil.format(parsedNumber, PNF.E164);
    }
    return '';
};

export const phoneValidator = (_: unknown, { countryCode, nationalPhoneNumber }: BookingFieldType['clientPhone']) => {
    const parsedNumber = parsePhoneNumber(countryCode, nationalPhoneNumber);
    if (parsedNumber && phoneUtil.isValidNumber(parsedNumber)) {
        return Promise.resolve();
    }
    return Promise.reject(strings.form.validation.validPhone);
};

export const handlesDST = (
    minBookingDateTime: string, // in UTC
    maxBookingDateTime: string, // in UTC
): Array<{ startDateTime: string; endDateTime: string }> => {
    let shouldHandleDST = false;

    let transitionTime = new Date(minBookingDateTime + ' UTC');

    while (isBefore(transitionTime, maxBookingDateTime)) {
        const localTime = convertToLocalDate(transitionTime);
        const nextLocalTime = add(transitionTime, { days: 1 });
        // Get the UTC offset for both local time and next local time
        const offsetLocalTime = new Date(localTime).getTimezoneOffset();
        const offsetNextLocalTime = new Date(nextLocalTime).getTimezoneOffset();
        // Check if the offsets are different, if yes there is a DST transition
        if (offsetLocalTime !== offsetNextLocalTime) {
            shouldHandleDST = true;
            break;
        }
        transitionTime = add(transitionTime, { days: 1 });
    }
    const dates = shouldHandleDST
        ? [
              {
                  startDateTime: minBookingDateTime,
                  endDateTime: convertToUtc(endOfDay(new Date(transitionTime))),
              },
              {
                  startDateTime: convertToUtc(startOfDay(add(new Date(transitionTime), { days: 1 }))),
                  endDateTime: maxBookingDateTime,
              },
          ]
        : [{ startDateTime: minBookingDateTime, endDateTime: maxBookingDateTime }];

    return dates;
};
