import { Flex } from 'antd';
import LocationSelect from '~/feature/externalBooking/ui/LocationSelect';
import { useExternalBookingContext } from '~/feature/externalBooking/context/ExternalBookingContext';
import DatePicker from '~/components/DatePicker';
import TimePicker from '~/components/TimePicker';
import { getAvailableTimeSlotsByLocalDate } from '~/feature/externalBooking/modules/selectors';

const styles: Record<string, React.CSSProperties> = {
    container: {
        paddingBottom: 24,
    },
};

const EventTimeForm: React.FC = () => {
    const {
        isLoading,
        eventType,
        availableDates,
        timeSlots,
        selectedDate,
        setSelectedDate,
        appointmentTime,
        setAppointmentTime,
    } = useExternalBookingContext();
    const showLocationSelect = eventType && !eventType.isVirtual;
    const availableTimeSlots =
        timeSlots && selectedDate ? getAvailableTimeSlotsByLocalDate(timeSlots, selectedDate) : [];

    return (
        <Flex gap="large" vertical style={styles.container}>
            {showLocationSelect && <LocationSelect />}
            <DatePicker
                isLoading={isLoading}
                selectedDate={selectedDate}
                onSelectDate={setSelectedDate}
                highlightDates={availableDates}
            />
            <TimePicker
                selectedDate={selectedDate}
                timeSlots={availableTimeSlots}
                selectedTime={appointmentTime}
                onSelectTime={setAppointmentTime}
            />
        </Flex>
    );
};

export default EventTimeForm;
